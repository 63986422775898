import { postRequest } from '..';

export function register(
  firstName,
  insertion,
  lastName,
  emailAddress,
  phoneNumber,
  description,
  clubName,
  functions,
  customFunction
) {
  return postRequest(
    '/dashboard/register',
    {
      firstName,
      insertion,
      lastName,
      emailAddress,
      phoneNumber,
      description,
      clubName,
      functions,
      customFunction
    },
  );
}