<template>
  <div 
    class="container mt-4 mb-3">
    <div 
      class="col-12 text-center"
      >
      <a 
        href="https://www.hockeyconnect.nl/"
      >
        <img
          src="https://hockeyconnect.blob.core.windows.net/hockey-connect/hcc/logo/logo-hc-community-color.png" 
          alt="Hockey Connect" 
          height="50"
        >
      </a>
      <p
        v-if="!submittedSuccessfully"  
      >
        <br>
        Indien u een e-mailadres van de club hebt, gelieve deze te gebruiken.
        <br>
        Het kan enkele dagen duren alvorens u de gegevens ontvangt om de app te activeren. 
        <br>
        Dit in verband met de verificatie.
      </p>
      <h1
        class="mt-2"
      >
        Registreren clubleden
      </h1>
    </div>
  </div>

  <form 
    v-if="!submittedSuccessfully"
    @submit.prevent="submit"
  >
    <div 
      class="container mb-5"
    >
      <div 
        class="card"
      >
        <div 
          class="card-header"
        >
          <h2>Persoonlijke informatie</h2>
        </div>
        <div 
          class="card-body"
        >
          <div 
            class="row mt-4"
          >
            <div 
              class="col-md-4 col-sm-12 mb-2"
            >
              <material-input
                id="first-name" 
                v-model="firstName" 
                is-required 
                variant="static"
                label="Voornaam*"
                placeholder="Vul voornaam in"
                />
            </div>
            <div 
              class="col-md-4 col-sm-12 mb-2"
            >
              <material-input 
                id="insertion" 
                v-model="insertion"
                variant="static" 
                label="Tussenvoegsel"
                placeholder="Vul tussenvoegsel in"
              />
            </div>
            <div 
              class="col-md-4 col-sm-12 mb-2"
            >
              <material-input 
                id="last-name" 
                v-model="lastName"
                is-required
                variant="static"
                label="Achternaam*"
                placeholder="Vul achternaam in"
              />
            </div>
          </div>
          <div 
            class="row mt-4"
          >
            <div
              class="col-md-6 col-sm-12 mb-2"
            >
              <material-input 
                id="email" 
                v-model="emailAddress" 
                is-required 
                type="email" 
                variant="static"
                label="E-mailadres*" 
                placeholder="Vul e-mailadres in (Club e-mail gewenst)" 
              />
            </div>
            <div 
              class="col-md-6 col-sm-12"
            >
              <material-input 
                id="phone-number" 
                v-model="phoneNumber" 
                type="phone" 
                variant="static" 
                label="Telefoonnummer"
                placeholder="Vul telefoonnummer in"
              />
            </div>
          </div>

          <div 
            class="row mt-4"
          >
            <div 
              class="col-12"
            >
              <material-textarea
                id="company-description" 
                v-model="description"
                variant="static" 
                label="Wilt u nog wat toevoegen?"
                placeholder="Tik hier uw eventuele toevoeging"
                :rows="3"
              />
            </div>
          </div>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>

    <div 
      class="container mb-5"
    >
      <div 
        class="card"
      >
        <div 
          class="card-header"
        >
          <h2>Bij welke club bent u betrokken?</h2>
        </div>
        <div
          class="card-body"
        >
          <div 
            class="row mb-2"
          >
            <div 
              class="col-md-4 col-sm-12"
            >
              <material-input 
                v-model="clubName" 
                is-required
                label="Naam van club*"
                placeholder="Clubnaam" 
                variant="static"
              />
            </div>
          </div>
          <div 
            class="row"
          >
            <div 
              class="col-md-4 col-sm-12 mb-3"
            >
              <h3>Bestuur</h3>
              <div 
                v-for="userFunction in boardFunctions" 
                :key="`${key}-${userFunction.id}`" 
                class="function"
              >
                <material-switch 
                  :id="`function-${userFunction.id}-switch`"
                  :name="`function-${userFunction.id}-switch`"
                  :model-value="roleSelected(userFunction.id)"
                  @update:modelValue="state => toggleRole(userFunction.id, state)">
                  {{ userFunction.title }}
                  <span v-if="userFunction.extraDescription"
                    :title="userFunction.extraDescription"><small>ⓘ</small></span>
                </material-switch>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 mb-3">
              <h3>Commissielid</h3>
              <div 
                v-for="userFunction in commissionFunctions" 
                :key="`${key}-${userFunction.id}`" 
                class="function"
              >
                <material-switch :id="`function-${userFunction.id}-switch`" :name="`function-${userFunction.id}-switch`"
                  :model-value="roleSelected(userFunction.id)"
                  @update:modelValue="state => toggleRole(userFunction.id, state)">
                  {{ userFunction.title }}
                </material-switch>
              </div>
            </div>
            <div 
              class="col-md-4 col-sm-12 mb-3"
            >
              <h3>Professional</h3>
              <div 
                v-for="userFunction in professionalFunctions" 
                :key="`${key}-${userFunction.id}`" 
                class="function"
              >
                <material-switch :id="`function-${userFunction.id}-switch`" :name="`function-${userFunction.id}-switch`"
                  :model-value="roleSelected(userFunction.id)"
                  @update:modelValue="state => toggleRole(userFunction.id, state)">
                  {{ userFunction.title }}
                </material-switch>
              </div>
            </div>
          </div>
          <div 
            class="row"
          >
            <div 
              class="col-md-4 col-sm-12"
            >
              <material-input
                v-model="customFunction"
                label="Anders, namelijk..."
                placeholder="Functie" 
                variant="static"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div 
      class="container mb-5"
    >
      <div 
        class="card"
      >
        <div 
          class="card-body"
        >
          <div
            class="row"
          >
            <div 
              class="col-md-9 col-sm-12"
            >
              <h5>Aanmelden voor de Hockey Connect Community app</h5>
              <p
                class="text-sm mb-0"
              >
                De Hockey Connect Community App is toegankelijk voor bestuurders en commissieleden
                van hockeyclubs.<br>Na verificatie zullen we u per mail van 
                inlog-gegevens voorzien.
                U kunt de app desgewenst al downloaden in de App Store of op Google Play.
              </p>
            </div>
            <div
              class="col-md-3 col-sm-12"
            >
              <material-button 
                color="primary" 
                variant="gradient-dark" 
                class="float-end mt-4 mb-0 mx-4"
              >
                
                Aanmelden
                <span 
                  v-show="loading"
                  class="spinner-border spinner-border-sm ms-2"
                  role="status" 
                  aria-hidden="true"
                />
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div 
    v-if="submittedSuccessfully"
    class="container"
  >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h2>Aanmelding verzonden</h2>
          </div>
          <div class="card-body">
            <p><b>Bedankt voor uw aanmelding.</b><br>
              Het kan enkele dagen duren 
              alvorens u de gegevens ontvangt de app te activeren.
              Dit in verband met de verificatie.</p>
          </div>

          <div class="card-footer">
            <material-button
              class="float-end"
              @click.prevent="resetForm"
            >
              Iemand anders aanmelden
            </material-button>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

import MaterialInput from '@/components/MaterialInput.vue';
import MaterialTextarea from '@/components/MaterialTextarea.vue';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialSwitch from '@/components/MaterialSwitch.vue';

import { register } from '@/api/providers/register';

export default {
  components: {
    MaterialInput,
    MaterialTextarea,
    MaterialButton,
    MaterialSwitch
  },

  data() {
    return {
      loading: false,
      submittedSuccessfully: false,
      
      // Basic user info
      firstName: '',
      insertion: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      description: '',

      // clubInfo
      clubName: '',
      functions: [],
      customFunction: '',


      // Used for role list initialization
      boardFunctions: [
        { id: 1, title: "Voorzitter" },
        { id: 2, title: "Vice-voorzitter" },
        { id: 3, title: "Penningmeester" },
        { id: 4, title: "Secretaris" },
        { id: 5, title: "Algemene zaken", extraDescription: "clubhuis, bar, sportcomplex, faciliteiten, gemeente etc." },
        { id: 6, title: "Commerciële zaken", extraDescription: "sponsoring, activatie, verhuur, club van… etc." },
        { id: 7, title: "Evenementen", extraDescription: "toernooien, feesten, Kampen etc." },
        { id: 8, title: "Technische zaken", extraDescription: "tophockey, breedtehockey, jeugd, senioren, jongste jeugd, parahockey etc." },
        { id: 9, title: "Communicatie & PR", extraDescription: "website, app, social media, pers etc." }
      ],

      commissionFunctions: [
        { id: 10, title: "Vrijwilligers" },
        { id: 11, title: "Sponsoring / club van 100 etc." },
        { id: 12, title: "Toernooi(en)" },
        { id: 13, title: "Evenementen" },
        { id: 14, title: "Arbitrage / scheidsrechters" },
        { id: 15, title: "Technische zaken senioren" },
        { id: 16, title: "Technische zaken junioren" },
        { id: 17, title: "Jeugdorganisatie" },
        { id: 18, title: "Tophockey" },
        { id: 19, title: "Ledenadministratie" },
        { id: 20, title: "Zaalhockey" },
        { id: 21, title: "Communicatie" },
        { id: 22, title: "Tuchtzaken" },
        { id: 23, title: "Hockeymaterialen" },
        { id: 24, title: "Accommodatiebeheer & -onderhoud" },
        { id: 25, title: "Kascontrole" },
        { id: 26, title: "Wedstrijdsecretariaat senioren" },
        { id: 27, title: "Wedstrijdsecretariaat junioren" },
        { id: 28, title: "Vertrouwenscommissie" },
        { id: 29, title: "Kleding, tenue etc." },
        { id: 30, title: "Barzaken" },
        { id: 31, title: "G-hockey & Parahockey" },
        { id: 32, title: "Lustrumzaken" },
        { id: 33, title: "Medische commissie" },
        { id: 34, title: "Keepers" },
      ],

      professionalFunctions: [
        { id: 35, title: "Verenigingsmanagement" },
        { id: 36, title: "Technisch management" },
        { id: 37, title: "Trainingscoördinatie" },
        { id: 38, title: "Combinatie-functionaris" },
        { id: 39, title: "Fysiotherapie" }
      ],

      key: 0
    }
  },

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
      addError: 'addError'
    }),


    resetForm() {
      this.firstName = '';
      this.insertion = '';
      this.lastName = '';
      this.emailAddress = '';
      this.phoneNumber = '';
      this.description = '';
      this.clubName = '';
      this.functions = [];
      this.customFunction = '';
      this.key++;

      this.submittedSuccessfully = false;
    },

    roleSelected(roleId) {
      return this.functions.includes(r => r.id = roleId);
    },

    toggleRole(roleId, state) {
      var roleIndex = this.functions.findIndex(r => r.id === roleId);
      if (roleIndex === -1 && state === true) {
        let roleToAdd = this.boardFunctions.find(r => r.id === roleId);
        roleToAdd = roleToAdd ?? this.commissionFunctions.find(r => r.id === roleId);
        roleToAdd = roleToAdd ?? this.professionalFunctions.find(r => r.id === roleId);

        if (roleToAdd !== undefined) this.functions.push(roleToAdd);

        return;
      }

      if (roleIndex > 1 && state === false) {
        this.functions = this.functions.filter(r => r.id !== roleId);
      }
    },

    async submit() {
      this.loading = true;

      try {
        await register(
          this.firstName,
          this.insertion,
          this.lastName,
          this.emailAddress,
          this.phoneNumber,
          this.description,
          this.clubName,
          this.functions,
          this.customFunction
        );

        this.submittedSuccessfully = true;
      }
      catch (error) {
        this.addError(error);
      }

      this.loading = false;
    }
  }
}

</script>

<style lang="scss">

@media(max-width: 767px)
{
  .function {
    input { 
      margin-block: 10px !important;
    }

    label { 
      padding-block: 10px; 
      width: 100%;
    }

    margin-bottom: 5px;
  }
}
</style>
